const LeadActionTypes = {
  SET_LEAD: 'SET_LEAD',
  RELOAD_LEAD: 'RELOAD_LEAD',
  RELOAD_CUSTOMER: 'RELOAD_CUSTOMER',
  RELOAD_OPPORTUNITY: 'RELOAD_OPPORTUNITY',
  RELOAD_QUOTE: 'RELOAD_QUOTE',
  RELOAD_JOB: 'RELOAD_JOB',
};

export default LeadActionTypes;
