import { axiosPost, axiosGet, axiosPut } from '../axios/config';

export const login = (user) => {
  return axiosPost('/user/login', user);
};

export const getCurrentUser = (data) => {
  return axiosGet('/user/profile', data);
};

export const updateLastLoginDateApi = () => {
  return axiosPost('/user/last-login');
};

export const changePassword = (data) => {
  return axiosPost('/user/change-password', data);
};

export const signUp = (data) => {
  return axiosPost('/user/signup', data);
};

export const updateLastLogin = (data) => {
  return axiosPut('/user/update-last-login', data);
};

export const updateCompansation = (data) => {
  return axiosPut('/user/update-compensation', data);
};

export const resendLink = (data) => {
  return axiosPost(`/user/resend-verification/${data}`);
};

export const forgotPasswordLinkSend = (data) => {
  return axiosPost(`/user/forgot-password`, data);
};

export const changePasswordWithLink = (data) => {
  return axiosPost(`/user/change-password-with-link`, data);
};

export const GetUserWithSetToken = (token) => {
  return axiosGet(`/user/get-user-with-set-token/${token}`);
};

export const sendInvitationLink = (data) => {
  return axiosPost(`/user/send-invitation-link`, data);
};
